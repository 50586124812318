/*.footer {*/
/*    color: #fff;*/
/*    background-color: #044599;*/
/*    align-items: center;*/
/*    justify-content: center;*/
/*}*/

/*.centralText {*/
/*    margin-top: 0.7rem;*/
/*    padding: 0.5rem 0;*/
/*}*/

.footer {
    color: #fff;
    margin-top: 2.5rem;
}

.centralText {
    padding: 0.5rem 0;
}

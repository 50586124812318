.filterBox {
  display: flex;
  margin: 1rem;
}

.filterBox div {
  margin: 0 0.5rem;
}

/* X-Small devices (portrait phones, less than 576px)
    No media query for `xs` since this is the default in Bootstrap */

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  .headerBlock {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .titleContainer {

  }

  .actionContainer {
    align-content: center;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
}

/* X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}

/* XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
}

.actionBtn {
  margin: 1.5rem 1rem 0 0;
}

.tagBox {
  display: grid;
  grid-template-columns: 10% 1fr;
  grid-column-gap: 1rem;
}

.tagBoxTitle {
  font-style: italic;
}

.imagesBox {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1rem;
}

.splitBox {
  display: grid;
  grid-template-columns: 1fr 1fr;
}


.activeTag {
    color: #ffe682 !important;
}

/*// Small devices (landscape phones, 576px and up)*/
@media (min-width: 576px) {
    .fullHeight {
        height: 100vh;
    }
    .columnItems {
        flex-direction: column !important;
        height: 100%;
    }
}

/*// Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) {

}

/*// Large devices (desktops, 992px and up)*/
@media (min-width: 992px) {  }
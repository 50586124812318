.titleIcon {
  display: grid;
  grid-template-columns: 20% 1fr;
  justify-items: center;
  align-items: center;
}

.actionBtns {
  display: flex;
  justify-content: space-around;
}